$col_main: #5b9bd5;
$col_main_l: #8fc6fa;
$col_main_d: #2e75b6;
$col_sub: #3e75a7;
$col_sub_l: #8db6db;
$col_sub_d: #376a99;
$col_third: #bae8eb;
$col_third_l: #fff2f6;
$col_third_d: #ebccd4;
$col_fourth: #f1f9ff;
$col_fifth: #ffa1a1;
$col_fifth_l: #e1e3f3;
$col_fifth_d: #dfb1a4;
$col_green: #96c28f;
$col_green_l: #e0ecde;
$col_green_d: #84b07d;
$col_red_l: #ffeded;
$col_border: #b4b4b4;
$col_white: #ffffff;
$col_white_l: #ffffff54;
$col_white_d: #fffffff2;
$col_text_gray: #8f8f8f;

$col_tb_title: #6ca9cf;
$col_tb_subtitle: #e9f3f9;
$col_tb_thirdtitle: #fff3e1;
$col_tb_thirdtitle_2: #fffcf2;
$col_tb_fourthtitle: #ffeae3;
$col_tb_fifthtitle: #f9f9f9;

$col_hide: rgba(255, 255, 255, 0);
$p_cell: 0.4rem;
